import React from 'react';
import styled from 'styled-components';

const ImageLink = styled.a`
  margin-right: 15px;

  img {
    max-width: 65px;
    height: auto;
    vertical-align: middle;
  }
`;

export default function ProductImage({ productUrl, imageUrl }) {
  return (
    <ImageLink href={productUrl}>
      <img width="100" height="130" src={imageUrl} alt="" />
    </ImageLink>
  );
}
