var $ = require('jquery');
var __ = require('i18n');
var utils = require('utils');
var Button = require('button');
require('jquerySerializeObject');

const Review = function (options) {
    'use strict';
    starsUI();
    this.options = $.extend({'hideFormOnSubmit': true, 'renderNewReviewOnSubmit': true}, options);
    this.$form = $('#form_rate');
    this.$textarea = this.$form.find('textarea');
    $('.rating i')
        .on('click', this.onClickStar);



    $('.btn-vote').on('click', this.onClickStar);
    this.$form.on('submit', this.onSubmit.bind(this));
    this.$textarea.on('keypress', function (event) {
        if (this.$textarea.val().length > 0) {
            this.$textarea.addClass('expand');
        }
    }.bind(this));

    this.$textarea.on('blur', function (event) {
        if (this.$textarea.val().length === 0) {
            this.$textarea.removeClass('expand');
        }
    }.bind(this));
};

Review.prototype.onClickStar = function(event) {
    'use strict';
    var rating = $(this).data('value');
    if (rating !== undefined) {
        $('input[name="rating"]').val(rating);
    } else {
        console.error('star undefined');
    }
};

Review.prototype.onSubmit = function (event) {
    'use strict';
    event.preventDefault();
    var $form = $(event.currentTarget);
    var $btn = $form.find('.btn-primary');
    this.$form.find('#rate-status').addClass('hide').removeClass('alert-danger');

    var button = new Button($btn);
    button.loading();
    $.ajax({
        url: $form.attr('action'),
        dataType: 'json',
        type: 'post',
        data: $form.serializeObject(),
        cache: false
    })
        .done(function (data) {
            button.loaded();
            if (data.result === true) {
                window.location.reload();
            } else {
                this.handleSubmitError(data);
            }
        }.bind(this))
        .fail(function (jqXHR) {
            var error = utils.parseAjaxError(jqXHR);
            button.loaded();
            this.handleSubmitError({
                'errors': ['Lo sentimos, ha ocurrido un error y no se ha podido guardar tu valoración. Intentálo de nuevo mas tarde.']
            });
        }.bind(this));
};

Review.prototype.handleSubmitError = function (data) {
    'use strict';
    var errors = data.errors;
    var $feedback = this.$form.find('#rate-status');
    var message = '';
    $.each(errors, function (i, item) {
        message+= '<li>'+item+'</li>';
    });

    $feedback.html('<ul class="unstyled">'+message+'</ul>').removeClass('hide').addClass('alert-danger');
};

function starsUI() {
    'use strict';
    var strRatings = [
        __('Me ha decepcionado'),
        __('No me gusta'),
        __('Está bien'),
        __('Me gusta'),
        __('Me encanta')
    ];

    const $stars = $('.rating i');
    const starFilledClassName = 'icon-star--filled';
    $('.rating')
        .on('mouseenter', 'i', function (event) {
            const $icon = $(event.currentTarget);
            const pts = $icon.data('value');
            $('.rating-str').text(strRatings[pts - 1]).show();
            updateStarValue(pts);
        })
        .on('mouseleave', 'i', function (event) {
            const $icon = $(event.currentTarget);
            $icon.removeClass(starFilledClassName);
            $('.rating-str').hide();
        })
        .on('mouseleave', function (event) {
            var selectedValue = $('input[name="rating"]').val();
            updateStarValue(selectedValue);
        });

    function updateStarValue(scoreSelected) {
        $stars.removeClass(starFilledClassName);
        for (var i=0; i < scoreSelected; i++) {
            $stars.eq(i).addClass(starFilledClassName);
        }
    }
}

export default Review;
