export class DomHelper {
  constructor(window) {
    this.window = window;
  }

  findClosestAnchor(el) {
    return this.findClosest(el, function (el) {
      return typeof el.nodeName === 'undefined'
        ? false
        : el.nodeName.toLowerCase() === 'a';
    });
  }

  findClosestWithAttribute(el, attrName) {
    return this.findClosest(el, function (el) {
      return typeof el.hasAttribute === 'undefined'
        ? false
        : el.hasAttribute(attrName);
    });
  }

  findClosest(el, callback) {
    if (callback(el)) {
      return el;
    }

    const elParent = el.parentNode;
    if (elParent === null) {
      return null;
    }

    if (callback(elParent)) {
      return elParent;
    }

    return this.findClosest(elParent, callback);
  }

  getLinkType(link) {
    let linkType = 'text';

    if (this.isImage(link)) {
      linkType = 'image';
    } else if (this.isButton(link)) {
      linkType = 'button';
    }

    return linkType;
  }

  getLinkAttributes(link) {
    if (link === null) {
      return {
        linkDestination: null,
        linkAnchor: null,
      };
    }

    const { host } = this.window.location;
    const linkAttributes = {
      linkDestination: link.href,
      linkAnchor: link.text.trim(),
    };

    if (host.length > 0 && typeof link.getAttribute('href') === 'undefined') {
      return linkAttributes;
    }

    return linkAttributes;
  }

  getDataTrackingAttributes(node) {
    const data = {};
    const regexDataAttr = /^data\-tracking\-(.+)$/;

    for (const attrIndex in node.attributes) {
      const attr = node.attributes[attrIndex];
      if (attr === null || typeof attr === 'undefined') {
        console.error(`attribute "${attrIndex}" not found.`);
        continue;
      }

      if (regexDataAttr.test(attr.nodeName)) {
        const key = camelCase(attr.nodeName.match(regexDataAttr)[1]);
        data[key] = attr.nodeValue;
      }
    }

    if (typeof data.toggle !== 'undefined') {
      delete data.toggle;
    }

    return data;
  }

  isImage(anchorElement) {
    return (
      anchorElement.getElementsByTagName('img').length > 0 ||
      (this.window
        .getComputedStyle(anchorElement, null)
        .getPropertyValue('background-image') !== '' &&
        this.window
          .getComputedStyle(anchorElement, null)
          .getPropertyValue('background-image') !== 'none') ||
      (this.window
        .getComputedStyle(anchorElement, null)
        .getPropertyValue('background') !== '' &&
        this.window
          .getComputedStyle(anchorElement, null)
          .getPropertyValue('background')
          .indexOf(':url') !== -1)
    );
  }

  /**
   * Checks if a link is a button or resembles to a button.
   * It may fail if not strictly a button and we change CSS styles of elements resembling to button.
   * Does not work properly
   */
  isButton(link) {
    return (
      link.tagName === 'button' ||
      link.getElementsByTagName('button').length > 0
    );
  }
}

function camelCase(input) {
  return input.toLowerCase().replace(/-(.)/g, function (match, group1) {
    return group1.toUpperCase();
  });
}

function isLinkPointingSomewhere(link) {
  return (
    link !== '#' && link !== 'javascript:;' && link !== 'javascript:void(0);'
  );
}

function calculateLinkTargetType(link, host, regexpAffiliate) {
  const regexpSite = new RegExp(
    `(^#|^/|^(http:|https:)//${host}|^${host.replace('www.', '')})`
  );

  if (!link.match(regexpSite)) {
    return 'external';
  }

  if (link.match(regexpAffiliate)) {
    return 'affiliate';
  }

  return 'internal';
}
