var $ = require('jquery');
var Button = require('button');
var Coupon = function(buttonSelector, inputSelector, $wrapper) {
    'use strict';
    this.$wrapper = $wrapper;
    $wrapper.find('.coupon-toggler').on('click', function (event) {
        event.preventDefault();
        $('.coupon-input').removeClass('d-none');
        $(event.currentTarget).addClass('d-none');
        $('.coupon-input').find('input').focus();
    });

    $wrapper.find('.coupon-input').find('input').on('blur', function (event) {
        if ($(event.currentTarget).val().length === 0) {
            $(event.currentTarget).parents('.card-body').focus();
            $('.coupon-input').addClass('d-none');
            $('.coupon-toggler').removeClass('d-none');
        }
    });

    this.$wrapper.find(buttonSelector).on('click', onClickSubmit.bind(this));
    this.$input = this.$wrapper.find(inputSelector);
};

function onClickSubmit(event) {
    'use strict';

    var button = new Button($(event.currentTarget));
    button.loading();
    $.ajax({
        url: window._sharedData.config.url + 'cart/voucher',
        method: 'post',
        data: {'coupon': this.$input.val()}
    })
        .done(function (data) {
            button.loaded();
            if (data.result === false) {
                invalidCoupon(data.errors);
                return;
            }

            window.location.reload(true);
        })
        .fail(function (jqXHR) {
            button.loaded();
            invalidCoupon();
        });
}

function invalidCoupon(reason) {
    var sweetAlert = require('sweetalert');
    var message = 'El cupón introducido no es válido';
    if (typeof reason !== 'undefined') {
        message+= ': ' + reason;
    }

    sweetAlert({
        text: message,
        icon: 'error'
    });
}

module.exports = Coupon;
