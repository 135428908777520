import React from 'react';
import styled from 'styled-components';

const Wrapper = ({ className, children }) => {
  return (
    <div className={className}>
      {children}
    </div>
  );
};

const StyledOffCanvas = styled(Wrapper)`
  position: fixed;
  top: 0;
  bottom: 0;

  width: ${(props) => props.theme.offCanvas.width};
  max-width: 70%;
  display: flex;
  flex-direction: column;
  z-index: ${(props) => props.theme.modal.zindex};
  overflow: hidden;
  background-color: ${(props) => props.theme.whiteColor};
  transition: transform 0.25s ease;

  &.offcanvas-left {
    left: 0;
    transform: translate3d(-${(props) => props.theme.offCanvas.width}, 0, 0);
  }

  &.offcanvas-right {
    right: 0;
    transform: translate3d(${(props) => props.theme.offCanvas.width}, 0, 0);
  }
`;

export default function OffCanvas({ theme, className, children }) {
  return (
    <StyledOffCanvas theme={theme} className={className}>
      {children}
    </StyledOffCanvas>
  );
}
