import * as React from 'react';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { subscribe } from 'reffects-store';
import { dispatch } from 'reffects';
import Button from '../Form/Button';
import AddressFormFields from '../AddressForm/AddressFormFields/AddressFormFields';
import registerAddressFormFieldsEvents from '../AddressForm/events';
import {
  addressModalEditorAddressSelector,
  addressEditorModalLoadingPostalCodeSuggestions,
  addressModalEditorShowSelector,
  addressModalEditorLoadingSelector,
  addressModalEditorErrorsSelector,
} from './selectors';
import registerModalAddressFormEvents from './events';

const stateKey = 'addressModalEditor.address';
const addressName = 'modalAddress';
function ModalAddressForm({
  address,
  errors,
  show,
  errorsPrefixed = true,
  addressHandlerURL,
  postalCodeSuggesterURL,
  loading,
  loadingPostalCodeSuggestions,
  handleClose,
  handleSubmit,
}) {
  useEffect(() => {
    registerAddressFormFieldsEvents(addressName);
    registerModalAddressFormEvents();
  }, []);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Dirección</Modal.Title>
      </Modal.Header>
      <form action={addressHandlerURL} method="post" onSubmit={handleSubmit}>
        <Modal.Body>
          {show && (
            <AddressFormFields
              address={address}
              errors={errors}
              errorsPrefixed={errorsPrefixed}
              loadingPostalCodeSuggestions={loadingPostalCodeSuggestions}
              addressName={addressName}
              stateKey={stateKey}
              postalCodeSuggesterURL={postalCodeSuggesterURL}
            />
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={handleClose}
            disabled={loading}
          >
            cancelar
          </Button>
          <Button variant="primary" type="submit" loading={loading}>
            Guardar
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default subscribe(
  ModalAddressForm,
  (state) => {
    return {
      address: addressModalEditorAddressSelector(state),
      errors: addressModalEditorErrorsSelector(state),
      show: addressModalEditorShowSelector(state),
      loading: addressModalEditorLoadingSelector(state),
      loadingPostalCodeSuggestions: addressEditorModalLoadingPostalCodeSuggestions(
        state
      ),
    };
  },
  {
    handleSubmit(event) {
      event.preventDefault();
      dispatch({
        id: 'ADDRESS_MODAL_EDITOR_TO_BE_SAVED',
        payload: { stateKey },
      });
    },
    handleClose() {
      dispatch('ADDRESS_MODAL_CLOSED');
    },
  }
);
