
export const DialogConfirm = function({title, text, icon, onConfirm}) {
  import('sweetalert').then( ({default: swal}) => {
    swal({
      title,
      text,
      buttons: true,
      icon,
      dangerMode: true
    }).then((confirmed) => {
      if (confirmed) {
        onConfirm();
      }
    });
  });
}

export const DialogConfirmAndContinue = function({title, text, icon, url}) {
    DialogConfirm({
      title,
      text,
      icon,
      onConfirm: () => window.location.href = url
    });
}

export const DialogAlert = function({title, text, icon}) {
  import('sweetalert').then(({default: swal}) => {
    swal({
      title,
      text,
      icon
    });
  });
}
