import * as React from 'react';
import styled from 'styled-components';

const Message = styled.div`
  display: block;
  width: 100%;
  color: #dc3545;
`;

export default function InvalidMessage({ message }) {
  if (!message) {
    return <></>;
  }

  return <Message>{message}</Message>;
}
