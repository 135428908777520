import { trackRemoveFromCart } from '../../../tracking';
import {DialogConfirm} from "../../../dialogs";
import Coupon from "../../js/shop/Coupons";

const $ = require('jquery');
require('jquery.serialize.object');

const ERR_REASON_OVERTIMES = 10; // El cupón se ha utilizado todas las veces determinadas.
const ERR_REASON_WRONGUSER = 20; // El cupón sólo puede utilizarlo un usuario determinado.
const ERR_REASON_WRONGPRODUCT = 30; // El cupón sólo puede utilizarse con una serie limitada de productos.
const ERR_REASON_EXPIRED = 40; // Fecha del Cupón caducada.
const ERR_REASON_NOMORE_COUPONS = 50; // No se pueden aplicar más cupones.

const Cart = function ($wrapper, couponEnabled) {
  if (couponEnabled) {
    this.coupons = new Coupon('.btn-coupon', 'input[name="coupon"]', $wrapper);
  }
};

Cart.defaults = {
  btnRemoveSelector: '.product--remove',
  quantityInputSelector: 'input[name=units]',
  groupClass: 'product-qty-form-control mr-2',
};

/**
 * Añade un producto al carrito del usuario.
 *
 * @var url		URL a donde enviar los datos
 * @var data	Datos del formulario
 * @return Promise
 */
Cart.prototype.add = function (url, formData) {
  return $.ajax({
    url,
    type: 'post',
    data: formData,
    cache: false,
  });
};

Cart.prototype.changeProductQuantity = function () {
  $('#update-cart-btn').removeClass('d-none');
};

Cart.prototype.removeProduct = function (product) {
  event.preventDefault();
  const removeUrl = $(event.target).attr('href');
  DialogConfirm({
    title: 'Eliminar producto',
    text: '¿Estás seguro de eliminar este producto de tu carrito?',
    icon: 'warning',
    onConfirm: () => {
      trackRemoveFromCart(product);
      window.location.href = removeUrl;
    }
  });
};

export default Cart;
