import * as React from 'react';
import { dispatch } from 'reffects';
import { formatInputId } from '../../../components/AddressForm/utils';
import { ADDRESS_MODAL_EDITOR_ADDRESS_SELECTED } from '../../../components/ModalAddress/events';
import InvalidMessage from './InvalidMessage';

function AddressSelector({
  address,
  selected,
  prefix,
  onClickEdit,
  onChangeHandler,
}) {
  const inputID = formatInputId('addressId', prefix) + address.id;
  const handleClick = (e) => {
    e.preventDefault();
    onClickEdit(address);
  };

  return (
    <div className="form-check">
      <input
        type="radio"
        className="form-check-input"
        id={inputID}
        name={`${prefix}AddressId`}
        value={address.id}
        defaultChecked={selected}
        onChange={onChangeHandler}
      />
      <label className="form-check-label address-" htmlFor={inputID}>
        <strong>
          {address.firstName} {address.lastName}
        </strong>
        {address.address}, {address.city}, {address.provinceName},{' '}
        {address.postalCode} {address.countryName}&nbsp;
        <a href="#" onClick={handleClick}>
          editar
        </a>
      </label>
    </div>
  );
}

export default function UserAddresses({
  selectedAddressId,
  addresses,
  prefix,
  errors,
  onChangeHandler,
}) {
  const handleEditAddress = (address) => {
    dispatch({ id: ADDRESS_MODAL_EDITOR_ADDRESS_SELECTED, payload: address });
  };
  const handleNewAddress = () => {
    dispatch({ id: ADDRESS_MODAL_EDITOR_ADDRESS_SELECTED, payload: {} });
  };

  return (
    <>
      <InvalidMessage message={errors[`${prefix}Address`]?.title} />
      <ul className="list-unstyled list-address list-group">
        {addresses.map((address) => {
          return (
            <li className="list-group-item" key={`${prefix}-${address.id}`}>
              <AddressSelector
                selected={
                  selectedAddressId === address.id || addresses.length === 1
                }
                address={address}
                prefix={prefix}
                onClickEdit={handleEditAddress}
                onSelect={onChangeHandler}
              />
            </li>
          );
        })}
        <li className="list-group-item">
          <button
            type="button"
            className="btn btn-block btn-outline-secondary"
            onClick={handleNewAddress}
          >
            + Definir otra dirección
          </button>
        </li>
      </ul>
    </>
  );
}
