import axios from 'redaxios';
import qs from 'qs';

export function serializeUrlSearchParams(data) {
  return new URLSearchParams(qs.stringify(data, { arrayFormat: 'brackets' }));
}

export function get({ url, successFn, errorFn }) {
  axios
    .get(url)
    .then((response) => successFn(response.data))
    .catch((error) => errorFn(error));
}

export function post({ url, body, config, successFn, errorFn }) {
  let axiosConfig = config;
  if (axiosConfig.contentType === 'application/x-www-form-urlencoded') {
    axiosConfig = {
      ...config,
      transformRequest: [serializeUrlSearchParams],
      headers: { 'content-type': axiosConfig.contentType },
    };
  }

  return axios
    .post(url, body, axiosConfig)
    .then(
      (response) => successFn(response.data),
      (error) => {
        if (error.body) {
          errorFn(error.data);
        } else {
          errorFn(error);
        }
      }
    )
    .catch((error) => errorFn(error));
}

export function postAll({ requests, successFn, errorFn, alwaysFn }) {
  Promise.all(
    requests.map(({ url, body, config }) => {
      let axiosConfig = config;
      if (axiosConfig.contentType === 'application/x-www-form-urlencoded') {
        axiosConfig = {
          ...config,
          transformRequest: [serializeUrlSearchParams],
          headers: { 'content-type': axiosConfig.contentType },
        };
      }

      return axios.post(url, body, axiosConfig);
    })
  )
    .then(
      (responses) => {
        return successFn(responses.map((response) => response.data));
      },
      (response) => errorFn(response.data)
    )
    .catch((error) => errorFn(error))
    .then(() => alwaysFn());
}

export function put({ url, body, successFn, errorFn }) {
  axios
    .put(url, body)
    .then((response) => successFn(response.data))
    .catch((error) => errorFn(error));
}

export function deleteRequest({ url, successFn, errorFn }) {
  axios
    .delete(url)
    .then((response) => successFn(response.data))
    .catch((error) => errorFn(error));
}

export default {
  get,
  post,
  postAll,
  put,
  delete: deleteRequest,
  serializeUrlSearchParams,
};
