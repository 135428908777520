import { registerCoeffectHandler } from 'reffects';

export function registerCoeffects(store, window) {
  registerCoeffectHandler('sharedData', (key) => {
    return {
      sharedData: window._sharedData,
    };
  });

  registerCoeffectHandler('state.full', () => {
    return store;
  });
}
