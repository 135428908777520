import * as React from 'react';
import { useEffect } from 'react';
import { dispatch } from 'reffects';
import GroupInputLabel from '../pieces/GroupInputLabel';
import registerPostalCodeInputEvents from './events';
import { formatInputName } from '../utils';

export function fixPostalCodeFormat(postalCode) {
  if (postalCode === null) {
    return postalCode;
  }

  const strPostalCode = `${postalCode}`;
  if (strPostalCode.length === 0) {
    return postalCode;
  }

  return strPostalCode.length !== 5
    ? strPostalCode.padStart(5, '0')
    : strPostalCode;
}

export function PostalCodeInput({
  postalCode,
  addressName,
  onChange,
  stateKey,
  postalCodeSuggesterURL,
}) {
  useEffect(() => {
    registerPostalCodeInputEvents(addressName, postalCodeSuggesterURL);
  }, []);

  const handleChange = (e) => {
    const queriedPostalCode = e.target.value;
    onChange('postalCode', queriedPostalCode);
    if (/^(?:AD[0-9]{3})|(?:[0-9]{5})$/.test(queriedPostalCode)) {
      dispatch({
        id: `ADDRESS_POSTAL_CODE_SUGGESTION_LOADING-${addressName}`,
        payload: { postalCode: queriedPostalCode, addressName, stateKey },
      });
    }
  };

  return (
    <GroupInputLabel
      groupClassName="col-sm-4 pr-2"
      name={formatInputName('postalCode', addressName)}
      caption="Código Postal"
      value={postalCode}
      autocomplete="postal-code"
      onChange={handleChange}
      required
      key={`address-${addressName}-postalCode`}
    />
  );
}
