import React, { useState } from 'react';

import InputErrorMessage from './InputErrorMessage';

const LoginFormByPassword = ({
  endpoint,
  endpointMail,
  endpointRegister,
  endpointForgotPassword,
  errors,
  displayTitle = true,
}) => {
  const [loginState, setLoginState] = useState();

  const hasGeneralError = errors && errors.default;

  const hasEmailError = errors && errors.email;
  const emailInputClass = hasEmailError ? ' is-invalid' : '';

  const hasPasswordError = errors && errors.password;
  const passwordInputClass = hasEmailError ? ' is-invalid' : '';

  function onSubmit(event) {}
  return (
    <>
      <form id="frm-login" action={endpoint} method="post" onSubmit={onSubmit}>
        {displayTitle && <h1>Iniciar sesión</h1>}
        <input type="hidden" name="form-login" value="true" />
        <p>
          ¿No tienes cuenta en NewHomePC?{' '}
          <a href={endpointRegister}>Regístrate ahora</a>
        </p>

        {hasGeneralError && (
          <div className="alert alert-danger">
            <ul className="list-unstyled mb-0">
              {errors.default.map((error, i) => (
                <li key={`login-general-error${i}`}>{error}</li>
              ))}
            </ul>
          </div>
        )}

        <div className="form-group">
          <label htmlFor="frm-login-email">Email:</label>
          <input
            type="text"
            name="email"
            id="frm-login-email"
            required
            autoFocus="on"
            autoCorrect="off"
            spellCheck="false"
            autoCapitalize="none"
            className={`form-control${emailInputClass}`}
          />
          {hasEmailError &&
            errors.email.map((error, i) => (
              <InputErrorMessage
                key={`frm-login-mail-error-${i}`}
                message={error}
              />
            ))}
        </div>

        <div className="form-group">
          <label htmlFor="frm-login-password">Contraseña:</label>
          <input
            type="password"
            id="frm-login-password"
            name="password"
            required
            className="form-control"
          />
        </div>

        <button type="submit" className="btn btn-block btn-primary">
          Iniciar sesión
        </button>
        <a href={endpointForgotPassword} className="btn btn-block btn-link">
          He olvidado mi contraseña
        </a>
      </form>
    </>
  );
};

export default LoginFormByPassword;
