import * as React from 'react';
import { subscribe } from 'reffects-store';
import { dispatch } from 'reffects';
import { useEffect } from 'react';
import composeClassNames from '../../../../composeClassNames';
import InvalidInputHelp from '../components/InputHelp';
import registerUserIdentificationEvents from './events';
import { privacyAcceptedSelector } from './selectors';

function RegisteredUser({ email }) {
  return (
    <div className="card">
      <div className="card-body p-2">
        <div className="form-group row m-0">
          <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
            Email
          </label>
          <div className="col-sm-10">
            <input
              type="email"
              readOnly
              className="form-control-plaintext"
              id="staticEmail"
              value={email}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function WelcomeFeedback({ loginURL, onClickLogin }) {
  return (
    <div
      id="welcome-feedback"
      className="alert alert-warning alert-dismissible"
    >
      <h4 className="alert-heading">Continuar con tu cuenta</h4>
      <p>Inicia sesión para poder continuar con tu cuenta:</p>
      <a className="js-login btn btn-primary" onClick={onClickLogin}>
        <b>Iniciar sesión</b>
      </a>
      {/* <p>Si lo deseas puedes iniciar sesión para recordar tus datos y terminar antes tu proceso de compra: <a class="js-login" href="'.RouteHelper::url('shop.user.login').'" >Iniciar sesión</a></p> */}
      {/* <p>O puedes <a href="#" data-dismiss="alert" aria-label="Close">continuar como invitado</a> sin ningún problema!</p> */}
    </div>
  );
}

function UserIdentification({
  email,
  privacyAccepted,
  user,
  shopName,
  privacyURL,
  loginURL,
  errors,
  emailExists,
  onChangeHandler,
  onChangeAcceptTerms,
  onChangeEmail,
  onClickLogin,
}) {
  useEffect(() => {
    registerUserIdentificationEvents();
  }, []);

  if (!user.anonymous && !user.guest) {
    return <RegisteredUser email={user.email} />;
  }

  const showWelcomeFeedback = /* (user.anonymous && user.guest) || */ emailExists;
  const emailClassError = errors.email ? 'is-invalid' : '';
  const privacyClassError = errors.privacy ? 'is-invalid' : '';

  return (
    <>
      <div className="form-group form-group-floating-label">
        <input
          type="email"
          name="email"
          id="email"
          className={composeClassNames(['form-control', emailClassError])}
          placeholder="Email"
          value={email}
          onChange={onChangeEmail}
          required
        />
        <label htmlFor="email">Email</label>
        <InvalidInputHelp message={errors.email ? errors.email.title : null} />
      </div>
      {showWelcomeFeedback && (
        <WelcomeFeedback loginURL={loginURL} onClickLogin={onClickLogin} />
      )}

      <div className="form-group">
        <div className="form-check">
          <input
            className={composeClassNames([
              'form-check-input',
              privacyClassError,
            ])}
            type="checkbox"
            name="privacyAccepted"
            value="true"
            id="privacy"
            required
            onChange={onChangeAcceptTerms}
            checked={privacyAccepted}
          />
          <label className="form-check-label" htmlFor="privacy">
            He leído y acepto la{' '}
            <a
              className="modal-page"
              href={privacyURL}
              target="_blank"
              rel="noreferrer"
            >
              Política de Privacidad
            </a>{' '}
            de {shopName}
          </label>
          <InvalidInputHelp
            message={errors.privacy ? errors.privacy.title : null}
          />
        </div>
      </div>
    </>
  );
}

export default subscribe(
  UserIdentification,
  function mapStateToProps(state, props) {
    return {
      email: state.checkout.formData.email ?? '',
      emailExists: state.checkout.emailExists ?? false,
      privacyAccepted: privacyAcceptedSelector(state),
    };
  },
  {
    onChangeAcceptTerms(event) {
      const isChecked = event.target.checked;
      dispatch({
        id: 'CHECKOUT_FIELD_CHANGED',
        payload: { field: 'privacy', value: isChecked },
      });
    },

    onChangeEmail(event) {
      dispatch({ id: 'CHECKOUT_EMAIL_CHANGED', payload: event.target.value });
      if (event.target.checkValidity() === false) {
        return;
      }

      dispatch({
        id: 'CHECKOUT_EMAIL_CHECK',
        payload: event.target.value,
      });
    },

    onClickLogin(event) {
      dispatch('CHECKOUT_LOGIN_CLICKED');
    },
  }
);
