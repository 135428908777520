var $ = require('jquery');

var Button = function ($button, loadingClass) {
    loadingClass = typeof loadingClass === 'undefined' ? 'btn-loading' : loadingClass;
    this.$button = $button;
    this.loadingClass = loadingClass;
    this.originalText = $button.text();
};

Button.prototype.loading = function () {
    'use strict';
    this.$button.addClass(this.loadingClass);
    this.$button.attr('disabled', true);
};

Button.prototype.loaded = function () {
    'use strict';
    this.$button.removeClass(this.loadingClass);
    this.$button.attr('disabled', false);
};

module.exports = Button;