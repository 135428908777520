import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import composeClassNames from '../../../composeClassNames';

const rotate = keyframes`
   0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
`;
const StyledButton = styled.button`
  &.btn-loading {
    position: relative;
    min-width: 2em;
    color: transparent !important;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      top: calc(50% - 0.5em);
      left: calc(50% - 0.5em);
      width: 1em;
      height: 1em;
      border-radius: 50%;
      border: 0.15em solid hsla(0, 0%, 100%, 0.2);
      border-top-color: #fff;
      border-left-color: #fff;
      display: inline-block;
      animation: ${rotate} 0.75s linear infinite;
    }
  }
`;

export default function Button({
  variant,
  type = 'button',
  loading = false,
  disabled = false,
  block = false,
  onClick,
  children,
}) {
  return (
    <StyledButton
      type={type}
      className={composeClassNames([
        'btn',
        `btn-${variant}`,
        loading ? 'btn-loading' : '',
        block ? 'btn-block' : '',
      ])}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
}
