import * as React from 'react';
import styled from 'styled-components';
import composeClassNames from '../../../composeClassNames';
import InputFeedback from './InputFeedback';
import get from '../../../get';
import { formatInputId, formatInputName } from '../AddressForm/utils';

const WrapperFormControl = styled.div`
  border: ${(props) =>
    `${props.theme.inputBorderWidth} solid ${props.theme.inputBorderColor}`};
  padding: 0;
  position: relative;
  height: auto;

  &:focus,
  &:focus-within {
    border-color: ${(props) => props.theme.inputFocusedBorderColor};
    box-shadow: 0 0 0 0.2rem rgba(56, 153, 218, 0.25);
    outline: 0;
  }

  &::before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50px;
    background-position: center center;
    background-repeat: no-repeat;
    pointer-events: none;
    background-image: url('./../../../../../../../assets/checkout/dropdown-arrow.svg');
  }

  label {
    font-size: 12.5px;
    position: absolute;
    padding: ${(props) =>
      `calc(${props.theme.inputPaddingY} / 2) ${props.theme.inputPaddingX}`};
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    opacity: 0.7;
    display: block;
    pointer-events: none;
  }

  select {
    padding: 1.5em
      ${(props) =>
        `${props.theme.inputPaddingX} 0.35714em ${props.theme.inputPaddingX}`};
    width: 100%;
    cursor: pointer;

    display: block;
    font: inherit;
    margin: 0;
    height: auto;
    -webkit-appearance: none;
    -webkit-font-smoothing: inherit;
    border: none;
    background: transparent;
    line-height: normal;
    z-index: 0;

    &:focus {
      border-color: ${(props) => props.theme.inputBorderColor};
      box-shadow: none;
      outline: none;
    }
  }
`;

export default function SelectWithFloatingLabel({
  className,
  id,
  name,
  caption,
  children,
  error,
  value,
  autoComplete,
  required,
  onChange,
  disabled = false,
}) {
  const classes = composeClassNames(['form-group', className]);
  const validationClass = error != false ? 'is-invalid' : '';

  return (
    <div className={classes}>
      <WrapperFormControl
        className={composeClassNames(['form-control', validationClass])}
      >
        <label htmlFor={id}>{caption}</label>
        <select
          id={id}
          name={name}
          value={value}
          autoComplete={autoComplete}
          className="form-control"
          required={required}
          onChange={onChange}
          disabled={disabled}
        >
          {children}
        </select>
      </WrapperFormControl>
      {error && <InputFeedback type="invalid" message={error.title} />}
    </div>
  );
}
