import { registerCoeffectHandler } from 'reffects';
import { v4 as uuidv4 } from 'uuid';

export default function registerUUIDCoeffect() {
  registerCoeffectHandler('uuid', function uuid() {
    return {
      uuid: {
        newUuid: uuidv4(),
      },
    };
  });
}
