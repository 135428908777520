import React from 'react';
import styled from 'styled-components';
import ProductImage from './ProductImage';
import ProductDescription from './ProductDescription';

const ProductLi = styled.li`
  padding: 1rem;
  margin: 0;
  position: relative;

  &:hover {
    background-color: #f9f9f9;
  }

  .remove {
    position: absolute;
    top: $spacer;
    right: $spacer;
    text-align: center;
    color: #2d2a2a;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    line-height: 20px;

    &:hover {
      background-color: $white;
      text-decoration: none;
    }
  }
`;

const RemoveAction = styled.a`
  position: absolute;
  top: 1rem;
  right: 1rem;
  text-align: center;
  color: ${(props) => props.theme.textDarkColor};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 20px;

  &:hover {
    background-color: ${(props) => props.theme.whiteColor};
    text-decoration: none;
  }
`;

export default function ProductItem({ product }) {
  return (
    <ProductLi className="d-flex align-items-start">
      <RemoveAction href={product.removeURL} title="Eliminar">
        ×
      </RemoveAction>
      <ProductImage productUrl={product.url} imageUrl={product.imageURL} />
      <ProductDescription
        name={product.name}
        units={product.units}
        price={product.price}
      />
    </ProductLi>
  );
}
