import { coeffect, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { httpPost } from '../../../reffects/effectBuilders/http';

export const NEW_ADDRESS_SELECTED = 'NEW_ADDRESS_SELECTED';
export const ADDRESS_MODAL_EDITOR_ADDRESS_SELECTED =
  'ADDRESS_MODAL_EDITOR_ADDRESS_SELECTED';
export const FORM_ADDRESS_CLOSED = 'FORM_ADDRESS_CLOSED';

export default function registerModalAddressFormEvents() {
  registerEventHandler('ADDRESS_MODAL_CLOSED', () => {
    return {
      ...state.set({
        'addressModalEditor.showModal': false,
      }),
    };
  });
  registerEventHandler(NEW_ADDRESS_SELECTED, () => {
    return {
      ...state.set({
        'addressModalEditor.address': {},
        'addressModalEditor.showModal': true,
      }),
    };
  });

  registerEventHandler(
    'ADDRESS_MODAL_EDITOR_ADDRESS_SELECTED',
    (_, address) => {
      return {
        ...state.set({
          'addressModalEditor.address': address,
          'addressModalEditor.showModal': true,
        }),
      };
    }
  );

  registerEventHandler(FORM_ADDRESS_CLOSED, () => {
    return {
      ...state.set({
        'addressModalEditor:address': null,
        'addressModalEditor.showModal': false,
        [`addressModalEditor.loading`]: false,
      }),
    };
  });

  registerEventHandler(
    'ADDRESS_MODAL_EDITOR_TO_BE_SAVED',
    (coeffects, payload) => {
      const { address } = coeffects.state;

      return {
        ...httpPost({
          url: coeffects.sharedData.entry_data.urls.addressHandlerURL,
          body: address,
          successEvent: ['ADDRESS_MODAL_EDITOR_SAVED', payload.stateKey],
          errorEvent: ['ADDRESS_MODAL_EDITOR_SAVE_FAILED', payload.stateKey],
        }),
        ...state.set({
          [`addressModalEditor.loading`]: true,
        }),
      };
    },
    [
      coeffect('sharedData'),
      coeffect('state', { address: 'addressModalEditor.address' }),
    ]
  );

  registerEventHandler('ADDRESS_MODAL_EDITOR_SAVE_FAILED', (_, [response]) => {
    return {
      ...state.set({
        [`addressModalEditor.errors`]: response.errors,
        [`addressModalEditor.loading`]: false,
      }),
    };
  });

  registerEventHandler(
    'ADDRESS_MODAL_EDITOR_SAVED',
    (_, [response, stateKey]) => {
      console.log('saved');
      return {
        ...state.set({
          [stateKey]: response.data.address,
          [`addressModalEditor.loading`]: true,
        }),
        dispatch: {
          id: 'ADDRESSES_UPDATED',
          payload: { address: response.data.address, formName: 'xx' },
        },
      };
    }
  );
}
