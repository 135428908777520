export function httpGet({ url, successEvent, errorEvent }) {
  if (!Array.isArray(successEvent)) {
    successEvent = [successEvent];
  }

  if (!Array.isArray(errorEvent)) {
    errorEvent = [errorEvent];
  }

  return {
    'http.get': {
      url,
      successEvent: successEvent,
      errorEvent: errorEvent,
    },
  };
}

export function httpPost({ url, body, successEvent, errorEvent }) {
  if (!Array.isArray(successEvent)) {
    successEvent = [successEvent];
  }

  if (!Array.isArray(errorEvent)) {
    errorEvent = [errorEvent];
  }

  return {
    'http.post': {
      url,
      body,
      successEvent: successEvent,
      errorEvent: errorEvent,
    },
  };
}
