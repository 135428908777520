import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import Tracker from './utils/Tracker/Tracker';
import CartSidebar from '../../vendor/sepia/shop/src/UI/Boutique/components/CartSidebar/CartSidebar';
import events from './events';
import Theme from './theme';
import {
  USER_CLOSES_CART_SIDEBAR,
  USER_OPENS_CART_SIDEBAR,
} from '../../vendor/sepia/shop/src/UI/Boutique/events';
import 'cookieConsent';
import 'cookieConsent.css';
import {trackSearchSubmitted} from "../../vendor/sepia/shop/src/UI/tracking";

if (process.env.NODE_ENV === 'production' && window.Sentry) {
  window.Sentry.init(window._sharedData.config.sentryConfig);
  if (window._sharedData.config.sentryUserConfig) {
    window.Sentry.setUser(window._sharedData.config.sentryUserConfig);
  }
}

// require('./polyfill/object.assign');
const $ = require('jquery');
require('bootstrap-dropdown');
require('bootstrap-collapse');

const App = function () {
  const $body = $('body');
  const tracker = new Tracker();
  tracker.install(window);

  const $formSearch = $('.form-search');
  $formSearch.on('submit', function (event) {
    const query = $formSearch.find('input[name="q"]').val();
    trackSearchSubmitted(query);
  });

  // Search Categories dropdown
  $('.form-search select').on('change', function (event) {
    event.preventDefault();
    const $select = $(event.currentTarget);
    const $option = $select.find('option:selected');
    const termId = $select.val();
    $('#dropdownSearchCategories').text($option.text());
  });

  // .nav-terms behaviour, avoid closing when uncollapsing submenu
  $('.nav-terms').on('click', '.nav-link--submenu', function (e) {
    e.stopPropagation();
  });

  // Cart sidebar widget
  // ------------------------
  const cartSideBar = document.getElementById('cart-sidebar');
  if (cartSideBar) {
    ReactDOM.render(
      <CartSidebar
        theme={Theme}
        cart={window._sharedData.entry_data.cart}
        events={events}
      />,
      cartSideBar
    );
  }

  $('.cart-link').on('click', function (event) {
    event.preventDefault();
    events.emit(USER_OPENS_CART_SIDEBAR);
  });

  $('.site-header-menu-toggle--button').on('click', function (event) {
    event.preventDefault();
    $body.addClass('offcanvas-opened mobile-nav-side-opened');
  });

  $(document).keyup(function (e) {
    if (e.key === 'Escape') {
      // escape key maps to keycode `27`
      if ($body.hasClass('offcanvas-opened')) {
        events.emit(USER_CLOSES_CART_SIDEBAR);
        $body.removeClass('offcanvas-opened cart-side-opened');
        $body.removeClass('mobile-nav-side-opened');
      }
    }
  });

  $body.on('click', '.close-side', function (event) {
    event.preventDefault();
    events.emit(USER_CLOSES_CART_SIDEBAR);
    $body.removeClass('offcanvas-opened mobile-nav-side-opened');
  });

  cookieConsent();
  loadWebFonts();
  categoriesNav();
  mobileNav();
  brokenImages();
  enableCSSTransitions();
  // $(document).ready(productListSameHeights);
};

function loadWebFonts() {
  /* var WebFont = require('webfontloader');
    var config = WebFont.Config = {
        google: {
            families: [
                'Open Sans:400,700'
            ]
        }
    };
    WebFont.load(config);
    */
}

function cookieConsent() {
  // Check if it has the consent cookie
  const cookieName = 'cookieconsent_dismissed';
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${cookieName}`);
  if (parts.length === 2) {
    return;
  }

  window.cookieconsent.initialise({
    container: document.getElementById("cookie-consent"),
    palette: {
      popup: {
        background: '#edeff5',
        text: '#838391',
      },
      button: {
        background: '#3899da',
        text: '#edeff5',
      },
    },
    cooke: {
      name: cookieName,
    },
    content: {
      message:
        'Esta página web utiliza cookies para asegurarse que obtienes una mejor experiencia de uso.',
      dismiss: 'Entendido!',
      link: 'Más información',
      href: 'https://newhomepc.net/pages/informacion-sobre-cookies',
    },
  });
}

function categoriesNav() {
  // nav-link--submenu
  const $categoriesNav = $('.nav-terms');
  $categoriesNav.on('click', '.nav-link--submenu', function (event) {
    event.preventDefault();
    const $elm = $(event.currentTarget);
    const $li = $elm.parent('.nav-item');

    $elm.toggleClass('opened');
    $li.find('> .nav').toggleClass('opened');
    $li.find('> ul.nav').attr('aria-hidden', 'false');

    const direction = $elm.hasClass('opened')
      ? 'chevron-down'
      : 'chevron-right';
    changexlinkhref($elm.find('.icon svg use').get(0), direction);
  });
}

function changexlinkhref(elm, value) {
  let href = elm.getAttributeNS('http://www.w3.org/1999/xlink', 'href');
  console.log(href);
  href = `${href.split('#')[0]}#${value}`;

  elm.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', href);
}

function productListSameHeights() {
  const $products = $('.product--item');
  const heights = $products
    .map(function () {
      return $(this).height();
    })
    .get();

  const maxHeight = Math.max.apply(null, heights);
  $products.css('height', maxHeight);
}

function mobileNav() {
  const $mobileOffcanvas = $('.mobile-nav-side');

  $mobileOffcanvas
    .find('.nav-link.justify-content-between')
    .on('click', function (event) {
      const $a = $(event.currentTarget);
      const hasChildren = $a.find('.caret').length > 0;
      if (hasChildren) {
        event.preventDefault();
        $a.find('.caret').toggleClass('fa-rotate-270');
        $a.parent('li').find('> ul.nav').toggleClass('closed');
      }
    });
}

function brokenImages() {
  $('.product--image img').on('error', function (event) {
    const $img = $(this).parent('.product--image');
    $img.addClass('product--image--broken');
  });
}

function enableCSSTransitions() {
  $('body').removeClass('preload');
}

export default App;
