function getDataLayer() {
  return (window.dataLayer = window.dataLayer || []);
}

function trackSearchSubmitted(query) {
  getDataLayer().push({
    event: 'butik.product-search',
    query: query,
  });
}

function trackProductView(product, list) {
  try {
    const payload = {
      event: 'eec.detail',
      ecommerce: {
        detail: {
          actionField: {
            list: list,
          },
          products: [
            {
              id: product.reference,
              name: product.name,
              category: product.category,
              brand: product.manufacturer
            },
          ],
        },
      },
    };

    getDataLayer().push(payload);
  } catch (e) {
    console.error(e);
  }
}

function trackAddToCart(data, list = 'Product Page') {
  try {
    const payload = {
      event: 'eec.add',
      ecommerce: {
        add: {
          actionField: {
            list,
          },
          products: [
            {
              id: data.product.reference,
              name: data.product.name,
              category: data.product.category,
              brand: data.product.manufacturer,
              quantity: data.product.quantity,
            },
          ],
        },
      },
    };

    const dataLayer = getDataLayer();
    dataLayer.push(payload);
  } catch (e) {
    console.error(e);
  }
}

function trackRemoveFromCart(product, list = 'Cart') {
  try {
    const payload = {
      event: 'eec.remove',
      ecommerce: {
        remove: {
          actionField: {
            list,
          },
          products: [
            {
              id: product.reference,
              name: product.name,
              category: product.category,
              brand: product.manufacturer,
              quantity: product.quantity,
            },
          ],
        },
      },
    };

    const dataLayer = getDataLayer();
    dataLayer.push(payload);
  } catch (e) {
    console.error(e);
  }
}

function trackCheckoutStart(checkoutData) {
  try {
    const payload = {
      event: 'eec.checkout',
      ecommerce: {
        checkout: {
          actionField: {
            step: 1,
          },
          products: [],
        },
      },
    };

    for (let i = 0; i < checkoutData.cart.length; i++) {
      const product = checkoutData.cart[i];
      payload.ecommerce.checkout.products.push({
        id: product.sku,
        name: product.name,
        category: product.category,
        brand: product.brand,
        price: product.price,
        quantity: product.quantity,
      });
    }

    const dataLayer = getDataLayer();
    dataLayer.push(payload);
  } catch (e) {
    console.error(e);
  }
}

function trackCheckoutStep(step, option) {
  try {
    const payload = {
      event: 'eec.checkout',
      ecommerce: {
        checkout: {
          actionField: {
            step,
            option,
          },
        },
      },
    };

    getDataLayer().push(payload);
  } catch (e) {
    console.error(e);
  }
}

function trackCheckoutOption(step, option) {
  try {
    const payload = {
      event: 'eec.checkout_option',
      ecommerce: {
        checkout_option: {
          actionField: {
            step,
            option,
          },
        },
      },
    };

    getDataLayer().push(payload);
  } catch (e) {
    console.error(e);
  }
}

function trackCheckoutTimeToSubmit(step, time, isGuestUser) {
  const payload = {
    event: 'form.submit',
    step,
    time,
    guestUser: isGuestUser ? 'first-time' : 'no-first-time',
  };

  getDataLayer().push(payload);
}

function trackPurchaseComplete(order) {
  try {
    const payload = {
      event: 'eec.purchase',
      ecommerce: {
        currencyCode: 'EUR',
        purchase: {
          actionField: {
            id: order.number,
            // affiliation: 'Simo\'s shop',
            revenue: `${order.totalAmount}`,
            tax: '0',
            shipping: '0',
            // coupon: 'SUMMER2019'
          },
          products: order.products.map((product) => {
            return {
              id: product.id,
              name: product.name,
              category: product.category,
              brand: product.brand,
              price: product.price,
              quantity: product.quantity,
            };
          }),
        },
      },
    };

    getDataLayer().push(payload);
  } catch (e) {
    console.error(e);
  }
}

export {
  trackSearchSubmitted,
  trackProductView,
  trackAddToCart,
  trackRemoveFromCart,
  trackCheckoutStart,
  trackCheckoutStep,
  trackCheckoutTimeToSubmit,
  trackPurchaseComplete,
};
