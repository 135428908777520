import React from 'react';
import styled from 'styled-components';
import Price from '../Price';

const Wrapper = styled.div`
  flex: 0 0 auto;
  border-top: 2px solid ${(props) => props.theme.borderColor};
  padding: 0 1rem;

  .total {
    padding: 1rem 0;
    margin: 0;

    strong {
      font-size: 1.1rem;
      color: ${(props) => props.theme.textDarkColor};
      font-weight: 600;
      text-transform: uppercase;
    }

    .amount {
      color: ${(props) => props.theme.brandColor};
      font-size: 1.2rem;
    }
  }

  .buttons {
    text-transform: uppercase;
  }
`;

export default function Footer({ total, shareURL, checkoutURL, cartURL }) {
  return (
    <Wrapper>
      <p className="total">
        <a href={shareURL}>Comparte tu selección de productos</a>
      </p>
      <p className="total d-flex justify-content-between align-items-center">
        <strong>Subtotal:</strong>
        <span className="amount">
          <Price amount={total} currency="&euro;" />
        </span>
      </p>
      <p className="buttons">
        <a
          href={cartURL}
          className="btn btn-light btn-block"
          data-translate="cart.header.view_cart"
        >
          Ver cesta
        </a>
        <a href={checkoutURL} className="btn btn-primary btn-block">
          Realizar pedido
        </a>
      </p>
    </Wrapper>
  );
}
