import { registerHttpBatteries } from 'reffects-batteries';
import { registerEffectHandler, dispatch } from 'reffects';
import HttpClient from '../HttpClient';

function dispatchEvent(event, ...data) {
  if (!event) {
    return;
  }
  const [id, ...rest] = event;
  dispatch({ id, payload: data.concat(rest) });
}

export default function registerHttpExtendedBatteries() {
  registerHttpBatteries(HttpClient);

  registerEffectHandler('http.delete', function deleteEffect({
    url,
    successEvent = [],
    errorEvent = [],
  }) {
    HttpClient.delete({
      url,
      successFn(response) {
        dispatchEvent(successEvent, response);
      },
      errorFn(error) {
        dispatchEvent(errorEvent, error);
      },
    });
  });
}


function adaptEvent(event) {
  if (Array.isArray(event)) {
    return event;
  }
  if (typeof event === 'string') {
    return [event];
  }
  if (event.payload) {
    return [event.id, event.payload];
  }
  return [event.id];
}

export function httpDelete({ url, successEvent, errorEvent = [], config }) {
  return {
    'http.delete': {
      url,
      successEvent: adaptEvent(successEvent),
      errorEvent: adaptEvent(errorEvent),
      config,
    },
  };
}
