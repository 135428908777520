import * as React from 'react';
import { useContext, useEffect } from 'react';
import { dispatch } from 'reffects';
import get from '../../../../get';
import GroupInputLabel from '../pieces/GroupInputLabel';
import { PostalCodeInput } from '../PostalCodeInput/PostalCodeInput';
import SelectWithFloatingLabel from '../../Form/SelectWithFloatingLabel';
import { formatInputId, formatInputName } from '../utils';
import GeographicalContext from '../../../geographicalDataContext';
import registerAddressFormFieldsEvents from '../events';

function getError(errors, fieldName, shouldPrefixName, prefix) {
  const prefixedFieldName = shouldPrefixName
    ? formatInputId(fieldName, prefix)
    : fieldName;

  return get(errors, prefixedFieldName, false);
}

export default function AddressFormFields({
  address = {},
  errors = {},
  errorsPrefixed = true,
  addressName,
  loadingPostalCodeSuggestions,
  stateKey,
  postalCodeSuggesterURL,
}) {
  useEffect(() => {
    registerAddressFormFieldsEvents(addressName);
  }, []);

  const setAddress = (newAddress) => {
    dispatch({
      id: `ADDRESS_FIELD_CHANGED-${addressName}`,
      payload: { address: newAddress, stateKey },
    });
  };

  const geographicalData = useContext(GeographicalContext);

  const handleCountryChange = (event) => {
    const newAddress = { ...address, countryCode: event.target.value };
    setAddress(newAddress);
  };

  const handleProvinceChange = (event) => {
    const newAddress = { ...address, provinceCode: event.target.value };
    setAddress(newAddress);
  };

  const handleChange = (field, value) => {
    const newAddress = { ...address, [field]: value };
    setAddress(newAddress);
  };

  return (
    <>
      <div className="row no-gutters">
        <GroupInputLabel
          groupClassName="col-sm-6 pr-2"
          name={formatInputName('firstName', addressName)}
          id={formatInputId('firstName', addressName)}
          caption="Nombre"
          value={get(address, 'firstName')}
          autocomplete="given-name"
          required
          onChange={(e) => handleChange('firstName', e.target.value)}
          error={getError(errors, 'firstName', errorsPrefixed, addressName)}
        />

        <GroupInputLabel
          groupClassName="col-sm-6"
          name={formatInputName('lastName', addressName)}
          id={formatInputId('lastName', addressName)}
          caption="Apellidos"
          value={get(address, 'lastName')}
          autocomplete="family-name"
          required
          onChange={(e) => handleChange('lastName', e.target.value)}
          error={getError(errors, 'lastName', errorsPrefixed, addressName)}
        />
      </div>

      <GroupInputLabel
        name={formatInputName('idfiscal', addressName)}
        id={formatInputId('idfiscal', addressName)}
        caption="DNI/NIF/Pasaporte"
        value={get(address, 'idfiscal')}
        onChange={(e) => handleChange('idfiscal', e.target.value)}
        error={getError(errors, 'idfiscal', errorsPrefixed, addressName)}
        required
      />

      <GroupInputLabel
        name={formatInputName('street', addressName)}
        id={formatInputId('street', addressName)}
        caption="Dirección"
        value={get(address, 'street')}
        autocomplete="shipping street-address"
        required
        error={getError(errors, 'street', errorsPrefixed, addressName)}
        onChange={(e) => handleChange('street', e.target.value)}
      />

      <div className="row no-gutters">
        <PostalCodeInput
          postalCode={get(address, 'postalCode')}
          addressName={addressName}
          onChange={handleChange}
          error={getError(errors, 'postalCode', errorsPrefixed, addressName)}
          postalCodeSuggesterURL={postalCodeSuggesterURL}
          stateKey={stateKey}
        />

        <SelectWithFloatingLabel
          caption="País"
          id={formatInputId('countryCode', addressName)}
          name={formatInputName('countryCode', addressName)}
          value={address.countryCode?.toLowerCase() ?? ''}
          error={getError(errors, 'countryCode', errorsPrefixed, addressName)}
          onChange={handleCountryChange}
          autoComplete="shipping country"
          required
          disabled={loadingPostalCodeSuggestions}
          className="col-sm-4 pr-2"
        >
          <option />
          {Object.keys(geographicalData).map((countryCode) => {
            return (
              <option key={`country-${countryCode}`} value={countryCode}>
                {geographicalData[countryCode].country.name}
              </option>
            );
          })}
        </SelectWithFloatingLabel>

        <SelectWithFloatingLabel
          id={formatInputId('provinceCode', addressName)}
          name={formatInputName('provinceCode', addressName)}
          caption="Provincia"
          value={get(address, 'provinceCode', '')}
          error={getError(errors, 'provinceCode', errorsPrefixed, addressName)}
          onChange={handleProvinceChange}
          disabled={loadingPostalCodeSuggestions}
          className="col-sm-4"
        >
          <option />
          {address.countryCode &&
            geographicalData[address.countryCode.toLowerCase()].provinces.map(
              (province) => (
                <option key={`province-${province.name}`} value={province.name}>
                  {province.name}
                </option>
              )
            )}
        </SelectWithFloatingLabel>
      </div>

      <GroupInputLabel
        name={formatInputName('city', addressName)}
        id={formatInputId('city', addressName)}
        caption="Población"
        value={get(address, 'city')}
        autocomplete="shipping address-level2"
        required
        error={getError(errors, 'city', errorsPrefixed, addressName)}
        onChange={(e) => handleChange('city', e.target.value)}
        disabled={loadingPostalCodeSuggestions}
      />

      <GroupInputLabel
        name={formatInputName('phone', addressName)}
        id={formatInputId('phone', addressName)}
        caption="Nº de teléfono"
        value={get(address, 'phone')}
        required
        type="tel"
        autocomplete="tel"
        onChange={(e) => handleChange('phone', e.target.value)}
        error={getError(errors, 'phone', errorsPrefixed, addressName)}
      >
        <small className="form-text text-muted">
          Nos permitirá ponernos en contacto contigo de forma rápida si ocurre
          algún incidente con tus pedidos.
        </small>
      </GroupInputLabel>

      <GroupInputLabel
        name={formatInputName('company', addressName)}
        id={formatInputId('company', addressName)}
        caption="Empresa (opcional)"
        value={get(address, 'company')}
        autocomplete="organization"
        onChange={(e) => handleChange('company', e.target.value)}
        error={getError(errors, 'company', errorsPrefixed, addressName)}
      />
    </>
  );
}
