import * as React from 'react';
import { useEffect, useState } from 'react';
import { dispatch } from 'reffects';
import { registerStateBatteries, store, subscribe } from 'reffects-store';
import { Modal } from 'react-bootstrap';
import registerHttpBatteries from '../../../../reffects/registerHttpBatteries';
import { registerCoeffects } from '../../../../reffects/coeffects';
import {
  BillingAddressForm,
  ShippingAddressForm,
} from '../components/AddressForm';
import UserAddresses from '../components/UserAddresses';
import ModalAddressForm from '../../../components/ModalAddress/ModalAddressForm';
import registerEvents from '../events';
import GeographicalDataContext, {
  geographicalData,
} from '../../../geographicalDataContext';
import UserIdentification from './UserIdentification';
import LoginForm from '../../../components/Login/Form';
import {
  checkoutContactFormErrorsSelector,
  displayLoginModal,
} from './selectors';

registerStateBatteries();
registerHttpBatteries();
registerCoeffects(store, window);

function CheckoutContactDetailsForm({
  shop,
  user,
  urls,
  userAddresses,
  shippingAddressId,
  billingAddressId,
  errors,
  addressHandlerURL,
  modalLoginShow,
  onChangeHandler,
  onSubmitHandler,
}) {
  useEffect(() => {
    registerEvents();
    dispatch({ id: 'CHECKOUT_ADDRESS_FORM_LOADED', payload: userAddresses });
  }, []);

  const [differentBillingAddress, toggleDifferentBillingAddress] = useState(
    false
  );
  const handleToggle = () =>
    toggleDifferentBillingAddress(!differentBillingAddress);

  return (
    <>
      <form
        method="POST"
        className="needs-validation"
        noValidate
        action={urls.checkout}
        onSubmit={onSubmitHandler}
      >
        <div className="mb-5">
          <UserIdentification
            user={user}
            errors={errors}
            shopName={shop.name}
            privacyURL={urls.privacy}
            loginURL={urls.login}
          />
        </div>

        <GeographicalDataContext.Provider value={geographicalData}>
          <div className="mb-5">
            <h4 className="font-weight-bold mb-3">Dirección de envío</h4>
            {userAddresses.length === 0 ? (
              <ShippingAddressForm
                errors={errors}
                postalCodeSuggesterURL={urls.postalCodeSuggesterURL}
              />
            ) : (
              <UserAddresses
                selectedAddressId={shippingAddressId}
                addresses={userAddresses}
                errors={errors}
                prefix="shipping"
                onChange={onChangeHandler}
              />
            )}
            <hr />
            <div className="form-group form-check">
              <input
                type="checkbox"
                name="differentBilling"
                id="different-billing"
                className="form-check-input"
                onChange={handleToggle}
              />
              <label className="form-check-label" htmlFor="different-billing">
                Datos de facturación distintos.
              </label>
            </div>

            <div
              style={{
                display: differentBillingAddress ? 'block' : 'none',
              }}
            >
              <h4 className="font-weight-bold mb-3">
                Dirección de facturación
              </h4>
              {userAddresses.length === 0 ? (
                <BillingAddressForm
                  errors={errors}
                  postalCodeSuggesterURL={urls.postalCodeSuggesterURL}
                />
              ) : (
                <UserAddresses
                  selectedAddressId={billingAddressId}
                  addresses={userAddresses}
                  errors={errors}
                  prefix="billing"
                />
              )}
            </div>
          </div>
          <ModalAddressForm
            addressHandlerURL={addressHandlerURL}
            postalCodeSuggesterURL={urls.postalCodeSuggesterURL}
            errorsPrefixed={false}
          />
        </GeographicalDataContext.Provider>

        <div className="d-flex justify-content-between align-items-center mb-4">
          <a href={urls.cart}>&lt; Volver al carrito</a>
          <button
            type="submit"
            className="btn btn-continue btn-primary btn-lg btn-block w-50"
          >
            <i className="icon-lock" /> Continuar al método de envío y pago
          </button>
        </div>
      </form>
      <Modal show={modalLoginShow}>
        <Modal.Header closeButton>
          <Modal.Title>Inicia sesión</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoginForm displayTitle={false} {...window.shopLoginForm} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default subscribe(
  CheckoutContactDetailsForm,
  (state, props) => {
    return {
      shippingAddressId: state.checkout.formData.shippingAddressId,
      billingAddressId: state.checkout.formData.billingAddressId,
      errors: checkoutContactFormErrorsSelector(state),
      userAddresses: state.checkout.userAddresses || props.userAddresses,
      modalLoginShow: displayLoginModal(state),
    };
  },
  {
    onChangeHandler(event) {
      const { name } = event.target;
      const { value } = event.target;

      dispatch({
        id: `CHECKOUT_FIELD_CHANGED`,
        payload: { field: name, value },
      });
    },
    onSubmitHandler(event) {
      // event.preventDefault();
      // dispatch('CHECKOUT_CONTACT_DETAILS_SUBMITTED');
    },
  }
);
