// Modal selectors
export function addressModalEditorShowSelector(state) {
  return state.addressModalEditor?.showModal ?? false;
}

export function addressModalEditorAddressSelector(state) {
  return state.addressModalEditor?.address ?? null;
}

export function addressEditorModalLoadingPostalCodeSuggestions(state) {
  return (
    state.addressModalEditor?.AddressFormLoadingPostalCodeSuggestions || false
  );
}

export function addressModalEditorLoadingSelector(state) {
  return state.addressModalEditor?.loading || false;
}

export function addressModalEditorErrorsSelector(state) {
  const errors = state.addressModalEditor?.errors || {};

  let converted = {};
  for (let i = 0; i < errors.length; i++) {
    converted[errors[i].id] = errors[i];
  }

  return converted;
}