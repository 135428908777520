import React from 'react';
import styled from 'styled-components';
import { USER_CLOSES_CART_SIDEBAR } from '../../events';

const Wrapper = styled.header`
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h3`
  padding: $spacer;
  border-bottom: 1px solid $border-color;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.a`
  text-decoration: none;
  font-size: 0.8rem;
  font-weight: 300;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  position: relative;
  color: #2d2a2a;
  cursor: pointer;
  padding-right: 20px;
`;

export default function Header({ events }) {
  function toggleVisibility() {
    events.emit(USER_CLOSES_CART_SIDEBAR);
  }

  return (
    <Wrapper>
      <Title>Cesta de compra</Title>
      <CloseButton onClick={toggleVisibility}>Cerrar</CloseButton>
    </Wrapper>
  );
}
