import React from 'react';

const LoginByEmail = ({ endpointMail, errors }) => {
  const emailClass = '';
  const hasErrors =
    typeof errors !== 'undefined' && typeof errors.default !== 'undefined';

  return (
    <form
      id="frm-login-mail"
      action={endpointMail}
      method="post"
      className={emailClass}
    >
      <h1>Iniciar sesión con email</h1>
      <p>
        Si has olvidado tu contraseña podemos enviarte un enlace a tu correo
        electrónico con el que podrás iniciar sesión automáticamente.
      </p>

      {hasErrors && (
        <div className="alert alert-danger">
          <ul className="list-unstyled mb-0">
            {errors.default.map((error, i) => (
              <li key={`login-general-error${i}`}>{error}</li>
            ))}
          </ul>
        </div>
      )}
      <input type="hidden" name="form-login" value="true" />

      <div className="form-group">
        <label htmlFor="frm-login-email">Email:</label>
        <input
          type="text"
          name="email"
          id="frm-login-email"
          required
          autoFocus="on"
          autoCorrect="off"
          spellCheck="false"
          autoCapitalize="none"
          className="form-control"
        />
      </div>
      <button type="submit" className="btn btn-block btn-primary">
        Enviar mail con enlace
      </button>
    </form>
  );
};

export default LoginByEmail;
