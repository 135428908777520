export default function get(object, property, defaultValue = '') {
  return typeof object[property] === 'undefined' || object[property] === null
    ? defaultValue
    : object[property];
}

function getPathArray(path) {
  return Array.isArray(path) ? path : path.split('.');
}

export function getDeep(obj, path, defaultValue = null) {
  if (typeof path === 'undefined') {
    return defaultValue;
  }

  return getPathArray(path).reduce((segment, index) => {
    return segment && segment[index];
  }, obj);
}