import { state } from 'reffects-store';
import { registerEventHandler } from 'reffects';

export default function registerEvents() {
  registerEventHandler('CHECKOUT_ADDRESS_FORM_LOADED', (_, userAddresses) => {
    return {
      ...state.set({
        userAddresses,
      }),
    };
  });
  /*
  registerEventHandler(ADDRESS_SELECTED, (_, address) => {
    return {
      ...state.set({
        selectedAddress: address,
        showModal: true,
      }),
    };
  }); */
  /*
  registerEventHandler(
    'ADDRESS_SAVED',

  ); */
  /*
   */
}
