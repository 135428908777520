import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';

export default function registerAddressFormFieldsEvents(addressName) {
  registerEventHandler(
    `ADDRESS_FIELD_CHANGED-${addressName}`,
    (_, { address, stateKey }) => {
      return {
        ...state.set({
          [stateKey]: address,
        }),
      };
    }
  );
}
