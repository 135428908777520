import * as React from 'react';
import styled from 'styled-components';
import composeClassNames from '../../../../composeClassNames';
import InputFeedback from '../../Form/InputFeedback';

const Input = ({
  id,
  type = 'text',
  name,
  value,
  placeholder,
  className,
  autocomplete,
  required,
  disabled = false,
  onChange,
  isInvalid,
}) => {
  const inputProps = {
    ...(autocomplete && { autoComplete: autocomplete }),
    ...(onChange && { onChange }),
  };

  return (
    <input
      type={type}
      name={name}
      id={id}
      className={composeClassNames([
        'form-control',
        className,
        isInvalid ? 'is-invalid' : '',
      ])}
      value={value === null ? '' : value}
      placeholder={placeholder}
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="off"
      disabled={disabled}
      required={required}
      {...inputProps}
    />
  );
};

const FormGroupFloating = styled.div`
  position: relative;
  margin-bottom: 1rem;

  input::placeholder {
    color: transparent;
  }

  & > label,
  & > input {
    padding: ${(props) =>
      `calc(${props.theme.inputPaddingY} + 6px) ${props.theme.inputPaddingX}`};
  }

  input {
    height: 46px;
  }

  & input:not(:placeholder-shown) {
    padding-top: calc(
      ${(props) => props.theme.inputPaddingY} +
        ${(props) => props.theme.inputPaddingY} * (2 / 3)
    );
    padding-bottom: calc(${(props) => props.theme.inputPaddingY} / 3);
  }

  & input:not(:placeholder-shown) ~ label {
    padding-top: calc(${(props) => props.theme.inputPaddingY} / 3);
    padding-bottom: calc(${(props) => props.theme.inputPaddingY} / 3);
    font-size: 12px;
    color: #777;
  }
`;

const FloatingLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default \`<label>\` margin */
  line-height: 1.5;
  color: #737373;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
  pointer-events: none; /* Fixes double click not triggering autofill */
`;

export default function GroupInputLabel(props) {
  const { groupClassName } = props;
  const isInvalid = props.error;

  return (
    <FormGroupFloating className={groupClassName}>
      <Input {...props} isInvalid={isInvalid} placeholder={props.caption} />
      <FloatingLabel htmlFor={props.id}>
        {props.caption}
      </FloatingLabel>
      {props.children}
      {props.error && (
        <InputFeedback type="invalid" message={props.error.title} />
      )}
    </FormGroupFloating>
  );
}

export function GroupSelectLabel(props) {}
