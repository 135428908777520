import React from 'react';
import styled from 'styled-components';
import Price from '../../Price'

const Wrapper = styled.div`
  padding-right: 20px;
  flex: 1 1 auto;

  .quantity {
    display: block;
    color: #bbb;
    .amount {
      color: ${(props) => props.theme.brandColor};
    }
  }
`;

const ProductName = styled.span`
  font-size: 0.85rem;
  margin-bottom: 6px;
  display: block;
`;

export default function ProductDescription({ name, units, price }) {
  return (
    <Wrapper>
      <ProductName>{name}</ProductName>
      <span className="quantity">
        {units} × <Price className="amount" amount={price} currency="&euro;" round={false} />
      </span>
    </Wrapper>
  );
}
