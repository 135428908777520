var $ = require('jquery');
require('bootstrap-input-spinner');

var FinanceWidget = function (installmentsData) {
    this.$widget = $('.alert__finance-widget');
    this.data = installmentsData;

    var $installments = this.$widget.find('input[name=installments]');
    $installments.inputSpinner();

    $installments.on('change', function (e) {
        var value = $(e.target).val();
        updateDisplayingInstallment(value - 2);
    });

    function updateDisplayingInstallment(installment) {
        if (typeof this.data.installments[installment] === 'undefined') {
            return;
        }

        var selected = this.data.installments[installment];
        $('.alert__finance-widget').find('.fee-amount').text(selected.amount);
        $('.alert__finance-widget').find('.fees').text(selected.months);
    }
};


module.exports = FinanceWidget;