module.exports = {
	parseAjaxError : function( jqXHR ){
		var error;
		try
		{
			var data = JSON.parse( jqXHR.responseText );
			if( data!==null && data.errors )
			{
				error = data.errors;
			}
			else
			{
				error = 'No se ha podido realizar la acción.';
			}
		}
		catch( err )
		{
			error = 'No se ha podido realizar la acción.';
		}

		return error;
	}
};