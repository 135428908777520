import React from 'react';
import styled from 'styled-components';

const PriceWrapper = styled.span`
  font-size: 1em;
`;

const PriceEnter = styled.span``;

const PriceDecimal = styled.span`
  font-size: 0.8em;
`;

const Currency = styled.span`
  font-size: 0.8em;
`;

function splitAmount(amount) {
  const [enter, decimal] = `${amount}`.split('.');

  return [enter, typeof decimal === 'undefined' ? null : decimal];
}

export function formatPrice(amount, decimals) {
  const [enter, decimal] = splitAmount(amount);
  const roundedDecimal = Math.round(decimal / 100);

  return `${enter}.${roundedDecimal}`;
}

export default function Price({ amount, currency, round = 2, className }) {
  const [enter, decimal] = splitAmount(amount);

  let roundedDecimal = decimal;
  if (round !== false && round > 0) {
    roundedDecimal = Math.round(decimal / 10 ** round);
  }

  return (
    <PriceWrapper className={className}>
      <PriceEnter>{enter}</PriceEnter>
      {decimal && <PriceDecimal>,{roundedDecimal}</PriceDecimal>}
      <Currency>{currency}</Currency>
    </PriceWrapper>
  );
}
