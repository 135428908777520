import React, { useState } from 'react';
import { Mail } from 'react-feather';
import LoginFormByPassword from './LoginFormByPassword';
import LoginByEmail from './LoginByEmail';

export default function Form({
  endpoint,
  endpointForgotPassword,
  endpointRegister,
  endpointMail,
  loadMode = 'password',
  errors,
  displayTitle = true,
}) {
  const [mode, setMode] = useState(loadMode);

  function switchLoginMode(newMode) {
    setMode(newMode);
  }

  return (
    <>
      {mode === 'password' ? (
        <LoginFormByPassword
          endpoint={endpoint}
          endpointForgotPassword={endpointForgotPassword}
          endpointRegister={endpointRegister}
          errors={errors}
          displayTitle={displayTitle}
        />
      ) : (
        <LoginByEmail endpointMail={endpointMail} errors={errors} />
      )}

      <div className="divider line">o inicia sesión mediante...</div>

      <div className={mode === 'password' ? 'd-none' : ''}>
        <button
          type="button"
          className="btn btn-block btn-outline-secondary"
          onClick={() => switchLoginMode('password')}
        >
          Iniciar sesión con contraseña
        </button>
        <small>Inicia sesión con tu email y contraseña.</small>
      </div>

      <div className={mode === 'email' ? 'd-none' : ''}>
        <button
          type="button"
          className="btn btn-block btn-outline-secondary"
          onClick={() => switchLoginMode('email')}
        >
          <Mail size={18} />
          <span> Iniciar sesión con email</span>
        </button>
        <small>
          Si has olvidado la contraseña o no tienes el usuario activado te
          enviaremos un correo con un enlace para iniciar sesión.
        </small>
      </div>
    </>
  );
}

export function CardForm(props) {
  return (
    <div className="card card--form pt-3 my-3">
      <div className="card-body">
        <Form {...props} />
      </div>
    </div>
  );
}
