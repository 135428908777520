import React from 'react';
import styled from 'styled-components';
import ProductItem from './ProductItem/ProductItem';

const ScrollableBlock = styled.section`
  display: flex;
  flex-grow: 1;
  overflow-y: scroll;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  flex-grow: 1;
`;

const ContentBody = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  text-align: left;
`;

export default function ProductList({ products }) {
  return (
    <ScrollableBlock>
      <Content>
        <ContentBody>
          <List>
            {Object.entries(products).map(([index, value]) => (
              <ProductItem key={`product-${value.id}`} product={value} />
            ))}
          </List>
        </ContentBody>
      </Content>
    </ScrollableBlock>
  );
}
