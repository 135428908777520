import { DomHelper } from './DomHelper';

/**
 * Tracking code.
 *
 * ## Instructions
 *
 * 1. You can add the following attributes to the DOM element you want to trigger an event ON CLICK:
 *    data-tracking-toggle="tracking":           Needed to enable this behaviour
 *    data-tracking-event="eventName":  The event name you want to place in the property `event`.
 *
 *    You can send any data you want, just add a data attribute prefixed by `data-tracking-*`.
 *    Example:
 *    data-tracking-myown-data="myvalue"   -> This will add the property `myownData:"myValue"` to the GTM event.
 *                                            Notice the dash-separated notation converted to camelCase.
 *
 * 2. In javascript do:
 *    var tracker = new Tracker();
 *    tracker.install();
 *
 *
 * Alternatively, you can trigger events on javascript as you want:
 *
 *    var tracker = new Tracker();
 *    tracker.trackClick(parameters); // See trackClick method to see what parameters are accepted.
 */

function gtmPolyfill(window) {
  // Install dummy GTM in case is not loaded so we can add commands anyway.
  if (typeof window.dataLayer === 'undefined') {
    window.dataLayer = (() => {
      const events = [];

      return {
        data: events,
        push(data) {
          events.push(data);
        },
      };
    })();
  }
}


function eventListener(event) {
  let { target } = event; // where was the click
  const toggle = target.getAttribute('data-tracking-toggle');

  if (toggle !== 'tracking') {
    // Not on a data-toggle="tracker"? Not interested!
    // Check if up in the hierarchy there is a node with a data-tracking-toggle
    const emisor = this.dom.findClosestWithAttribute(
      event.target,
      'data-tracking-toggle'
    );

    if (emisor === null) {
      return;
    }

    target = emisor;
  }

  const dataAttributes = this.dom.getDataTrackingAttributes(target);
  const container = this.dom.findClosestWithAttribute(
    target,
    'data-container-name'
  );
  const areaContainer = container
    ? this.dom.findClosestWithAttribute(container, 'data-area-name')
    : null;
  const parameters = this.dom.getLinkAttributes(
    this.dom.findClosestAnchor(target)
  );

  parameters.event = dataAttributes.event
    ? dataAttributes.event
    : 'ui-interaction';
  parameters.containerName = container
    ? container.getAttribute('data-container-name')
    : null;
  parameters.areaName = areaContainer
    ? areaContainer.getAttribute('data-area-name')
    : target.getAttribute('data-area-name');

  // add other data-tracking-* attributes
  delete dataAttributes.event;
  for (const key in dataAttributes) {
    parameters[key] = dataAttributes[key];
  }

  this.trackClick(parameters);

  return false;
}

export default class Tracker {
  install(window) {
    gtmPolyfill(window);
    this.window = window;
    this.dom = new DomHelper(window);

    const body = window.document.querySelector('body');
    body.addEventListener('click', eventListener.bind(this));
  }

  trackClick(parameters) {
    this.window.dataLayer.push(parameters);
  }
}

/*
Tracker.prototype.appendCID = (target, parameters) => {
    const urlHelper = require('common/url-utils');
    let cid = '';
    for (var i = 0; i < 20; i++) {
        cid += Math.floor(Math.random() * 10);
    }

    target.href = urlHelper.updateQueryStringParameter(target.href, 'cid', cid);
    parameters.linkDestination = target.href;
    parameters.cid = cid;

    return parameters;
};
*/
