import { coeffect, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { httpGet } from '../../../../reffects/effectBuilders/http';

export default function registerPostalCodeInputEvents(
  addressName,
  postalCodeSuggesterURL
) {
  registerEventHandler(
    `ADDRESS_POSTAL_CODE_SUGGESTION_LOADING-${addressName}`,
    (coeffects, payload) => {
      const url = postalCodeSuggesterURL.replace(/\/+$/, '');

      return {
        ...state.set({
          [`${payload.addressName}AddressFormLoadingPostalCodeSuggestions`]: true,
        }),
        ...httpGet({
          url: `${url}/${payload.postalCode}`,
          successEvent: [
            `ADDRESS_POSTAL_CODE_SUGGESTION_LOADED-${addressName}`,
            payload.stateKey,
          ],
          errorEvent: [
            `ADDRESS_POSTAL_CODE_SUGGESTION_LOAD_FAILED-${addressName}`,
            payload.stateKey,
          ],
        }),
      };
    },
    [coeffect('sharedData')]
  );

  registerEventHandler(
    `ADDRESS_POSTAL_CODE_SUGGESTION_LOADED-${addressName}`,
    function suggestionLoaded(coeffects, [suggestion, stateKey]) {
      const { address } = coeffects.state;

      return {
        ...state.set({
          [`${stateKey}.countryCode`]: suggestion.country,
          [`${stateKey}.provinceCode`]: suggestion.province,
          [`${stateKey}.city`]: suggestion.city,
          [`${stateKey}.AddressFormLoadingPostalCodeSuggestions`]: false,
        }),
      };
    },
    [coeffect('state', { address: `${addressName}` })]
  );

  registerEventHandler(
    `ADDRESS_POSTAL_CODE_SUGGESTION_LOAD_FAILED-${addressName}`,
    (_, [reason, stateKey]) => {
      return {
        ...state.set({
          [`${stateKey}AddressFormLoadingPostalCodeSuggestions`]: false,
        }),
      };
    }
  );
}
