import * as React from 'react';
import { subscribe } from 'reffects-store';
import AddressFormFields from '../../../components/AddressForm/AddressFormFields/AddressFormFields';

function AddressForm({
  address,
  errors,
  geographicalData,
  addressName,
  stateKey,
  loadingPostalCodeSuggestions,
  postalCodeSuggesterURL,
}) {
  return (
    <AddressFormFields
      address={address}
      errors={errors}
      geographicalData={geographicalData}
      loadingPostalCodeSuggestions={loadingPostalCodeSuggestions}
      addressName={addressName}
      stateKey={stateKey}
      postalCodeSuggesterURL={postalCodeSuggesterURL}
    />
  );
}

const ShippingAddressForm = subscribe(AddressForm, (state, props) => {
  return {
    addressName: 'shippingAddress',
    stateKey: 'checkout.formData.shippingAddress',
    address: state.checkout.formData?.shippingAddress || props.address,
    loadingPostalCodeSuggestions:
      state.shippingAddressFormLoadingPostalCodeSuggestions || false,
  };
});

const BillingAddressForm = subscribe(AddressForm, (state, props) => {
  return {
    addressName: 'billingAddress',
    stateKey: 'checkout.formData.billingAddress',
    address: state.checkout.formData?.billingAddress || props.address,
    loadingPostalCodeSuggestions:
      state.billingAddressFormLoadingPostalCodeSuggestions || false,
  };
});

export { ShippingAddressForm, BillingAddressForm };
