import { registerStateBatteries, store } from 'reffects-store';
import { useEffect } from 'react';
import { registerCoeffects } from './coeffects';
import registerUUIDCoeffect from './coeffect.uuid';
import registerHttpExtendedBatteries from './registerHttpBatteries';
import registerHttpBatteries from "./registerHttpBatteries";

export default function initReffects(initialState = {}) {
  store.initialize(initialState);
  registerStateBatteries();
  registerHttpBatteries();
  registerCoeffects(store, window);
  registerUUIDCoeffect();
  registerHttpExtendedBatteries();

  return store;
}

export function useReffects(callback) {
  useEffect(callback, []);
}
