import { coeffect, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import {
  ADDRESS_MODAL_EDITOR_ADDRESS_SELECTED,
  FORM_ADDRESS_CLOSED,
} from '../../../components/ModalAddress/events';

export default function registerUserIdentificationEvents() {
  registerEventHandler(
    'CHECKOUT_CONTACT_FORM_USER_EDITS_ADDRESS',
    (_, { address }) => {
      return {
        dispatch: {
          id: ADDRESS_MODAL_EDITOR_ADDRESS_SELECTED,
          payload: { address },
        },
      };
    }
  );

  registerEventHandler(
    'ADDRESSES_UPDATED',
    (coeffects, { address, formName }) => {
      const addresses = coeffects.state.userAddresses;
      let isNewAddress = true;
      const newAddresses = addresses.map((_address) => {
        if (_address.id == address.id) {
          isNewAddress = false;
          return address;
        }

        return _address;
      });

      if (isNewAddress) {
        newAddresses.push(address);
      }

      return {
        ...state.set({
          'checkout.userAddresses': newAddresses,
        }),
        dispatch: FORM_ADDRESS_CLOSED,
      };
    },
    [coeffect('state', { userAddresses: 'checkout.userAddresses' })]
  );

  registerEventHandler('CHECKOUT_FIELD_CHANGED', (_, { field, value }) => {
    return {
      ...state.set({
        [`checkout.formData.${field}`]: value,
      }),
    };
  });

  registerEventHandler('CHECKOUT_EMAIL_CHANGED', (_, payload) => {
    return {
      ...state.set({
        'checkout.formData.email': payload,
      }),
    };
  });

  registerEventHandler(
    'CHECKOUT_EMAIL_CHECK',
    ({ sharedData }, email) => {
      return {
        ...http.post({
          url: `${sharedData.config.url}a/email`,
          body: { email },
          config: { contentType: 'application/x-www-form-urlencoded' },
          successEvent: 'CHECKOUT_EMAIL_CHECK_DONE',
          errorEvent: 'CHECKOUT_EMAIL_CHECK_FAILED',
        }),
      };
    },
    [coeffect('sharedData')]
  );

  registerEventHandler('CHECKOUT_EMAIL_CHECK_DONE', (_, [response]) => {
    let userExistsWarning = false;
    if (response.exists) {
      userExistsWarning = true;
    }

    return {
      ...state.set({
        'checkout.emailExists': userExistsWarning,
      }),
    };
  });

  registerEventHandler('CHECKOUT_LOGIN_CLICKED', (event) => {
    return {
      ...state.set({
        'checkout.modalLogin.show': true,
      }),
    };
  });

  registerEventHandler(
    'CHECKOUT_CONTACT_DETAILS_SUBMITTED',
    (coeffects) => {
      const { formData } = coeffects.state;
      console.log(formData);

      return {
        ...state.set({
          'checkout.saving': true,
        }),
      };
    },
    [coeffect('state', { formData: `checkout.formData` })]
  );

  registerEventHandler('CHECKOUT_CONTACT_DETAILS_SAVED', () => {
    return {
      ...state.set({
        'checkout.saving': false,
      }),
    };
  });

  registerEventHandler('CHECKOUT_CONTACT_DETAILS_SAVE_FAILED', () => {
    return {
      ...state.set({
        'checkout.saving': false,
      }),
    };
  });
}
