const Theme = {
  whiteColor: '#fff',
  brandColor: 'rgba(56, 153, 218, 1)',
  textDarkColor: '#2d2a2a',
  borderColor: '#dee2e6',

  // Offcanvas
  offCanvas: {
    width: '340px',
  },

  // Modal
  modal: {
    zindex: 1050,
  },

  // Input
  inputPaddingY: '0.375rem',
  inputPaddingX: '0.75rem',
  inputBorderColor: '#d9d9d9',
  inputBorderWidth: '1px',
  inputFocusedBorderColor: '#a4d0ee',
};

export default Theme;
