export function checkoutContactFormErrorsSelector(state) {
  const errors = state.checkout.formErrors ?? {};

  let converted = {};
  for (let i = 0; i < errors.length; i++) {
    converted[errors[i].id] = errors[i];
  }

  return converted;
}

export function privacyAcceptedSelector(state) {
  return state.checkout.formData.privacy ?? false;
}

export function displayLoginModal(state) {
  return state.checkout.modalLogin?.show ?? false;
}
