import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import Header from './Header';
import Footer from './Footer';
import ProductList from './ProductList';
import OffCanvas from '../Offcanvas/OffCanvas';
import {
  PRODUCT_ADDED_TO_CART,
  USER_CLOSES_CART_SIDEBAR,
  USER_OPENS_CART_SIDEBAR,
} from '../../events';
import useEventSubscription from '../../../useEventSubscription';

function eventSelector({ product }) {
  return {
    id: product.id,
    name: product.name,
    units: product.quantity,
    url: product.link,
    price: product.price,
    imageURL: product.imageUrl,
  };
}
/*
function scrollIntoProduct(productID){
  var topPos = document.getElementById('inner-element').offsetTop;
  document.getElementById('container').scrollTop = topPos-10;
}
*/
export default function CartSidebar({ theme, cart, events }) {
  const [products, setProducts] = useState(cart.products);
  const [totalAmount, setTotalAmount] = useState(cart.total);
  // const [lastProduct, setLastProduct] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    if (visible) {
      body.classList.add('offcanvas-opened', 'cart-side-opened');
    } else {
      body.classList.remove('offcanvas-opened', 'cart-side-opened');
    }
  }, [visible]);

  // Listen for cart additions
  useEventSubscription(events, PRODUCT_ADDED_TO_CART, (data) => {
    const product = eventSelector(data);
    const productsCopy = { ...products, ...{[product.id]: product}};
    setProducts(productsCopy);
    setTotalAmount(data.cart.total);
    events.emit(USER_OPENS_CART_SIDEBAR);
  });

  // Scroll into last element added
  // useEffect(() => {
  //  scrollIntoProduct(lastProduct.id);
  // }, [lastProduct]);

  useEventSubscription(events, USER_OPENS_CART_SIDEBAR, () => {
    setVisible(true);
  });
  useEventSubscription(events, USER_CLOSES_CART_SIDEBAR, () => {
    setVisible(false);
  });

  return (
    <ThemeProvider theme={theme}>
      <OffCanvas className="offcanvas-right cart-widget-side">
        <Header events={events} />
        <ProductList products={products} />
        <Footer
          total={totalAmount}
          cartURL={cart.cartURL}
          shareURL={cart.shareURL}
          checkoutURL={cart.checkoutURL}
        />
      </OffCanvas>
    </ThemeProvider>
  );
}
